import { useAppSelector } from "Hooks/useAppSelector";
import { PurchaseStep } from "State/Contracts/Purchase/PurchaseState";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

const PageResources = Resources.Contract.Detail.Purchase;

export const usePurchaseTitle = () => {
  const { t } = useResource();
  const { step } = useAppSelector(s => s.contracts.purchase);

  const pageTitle = useMemo(() => {
    switch (step) {
      case PurchaseStep.Configuration:
        return t(PageResources.Target.Title);
      case PurchaseStep.AkatNotice:
        return t(PageResources.Target.AkatNotice.Title);
      case PurchaseStep.Success:
        return t(PageResources.Target.Success.Title);
      default:
        return "";
    }
  }, [step, t]);

  return pageTitle;
};
