import { useAppSelector } from "Hooks/useAppSelector";
import { InvestUninvestedDepositsStep } from "State/Contracts/UninvestedDeposits/UninvestedDepositsReducer";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

const PageResources = Resources.Contract.Detail.Purchase;

export const useInvestUninvestedDepositsTitle = () => {
  const { t } = useResource();
  const { step } = useAppSelector(s => s.contracts.uninvestedDeposits);

  const pageTitle = useMemo(() => {
    switch (step) {
      case InvestUninvestedDepositsStep.Configuration:
        return t(PageResources.Target.Title);
      case InvestUninvestedDepositsStep.AkatNotice:
        return t(PageResources.Target.AkatNotice.Title);
      case InvestUninvestedDepositsStep.Signature:
        return t(PageResources.Target.Title);
      case InvestUninvestedDepositsStep.Success:
        return t(PageResources.Target.Success.Title);
      default:
        return "";
    }
  }, [step, t]);

  return pageTitle;
};
