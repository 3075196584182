import { AMLQuestions } from "Components/ContractCreate/AMLQuestions/AMLQuestions";
import { BankConnection } from "Components/ContractCreate/BankConnection";
import { BankIDPage } from "Components/ContractCreate/BankID/BankIDPage";
import { ContactInformation } from "Components/ContractCreate/ContactInformation/ContactInformation";
import { ContractPreview } from "Components/ContractCreate/ContractPreview";
import { ContractSignature } from "Components/ContractCreate/ContractSignature";
import { Documents } from "Components/ContractCreate/Documents/Documents";
import { FinalPage } from "Components/ContractCreate/FinalPage/FinalPage";
import { LastInformation } from "Components/ContractCreate/LastInformation";
import { PathSelection } from "Components/ContractCreate/PathSelection";
import { PersonalData } from "Components/ContractCreate/PersonalData/PersonalData";
import { PersonalDataVerification } from "Components/ContractCreate/PersonalDataVerification";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  CONTRACT_STEPS,
  setIsAkatNoticeVisible,
} from "State/Contracts/Create/CreateState";
import { NewContractStep } from "State/Contracts/Create/Models";

import { useEffect, type FunctionComponent, useMemo, useRef } from "react";
import { Navigate, useNavigate } from "react-router";
import { useViewTracking } from "Hooks/useViewTracking";
import {
  getDefaultTrackingUrlBase,
  setCustomDefaultUrlPostfix,
} from "Utils/TrackingUtils";
import { getBusinessSectorAsync } from "State/CodeLists/BusinessSector/GetBusinessSectorState";
import { Resources, useResource } from "Translations/Resources";
import { getProcessInformationAsync } from "State/Contracts/Create/ProcessInformation/getProcessInformation";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { ContractModelingStep } from "State/Contracts/Modeling/Models";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { useRehydrateContractDraft } from "Hooks/Contract/useRehydrateContractDraft";
import { useConfirmationDialog } from "Hooks/Dialogs/useConfirmationDialog";
import { useTerminateContract } from "Hooks/Contract/useTerminateContract";
import { ConfirmValues } from "Components/Shared/Dialogs/ConfirmDialog";
import {
  resetBiometricSignatureError,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { useCreateContract } from "Hooks/Contract/useCreateContract";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { useDesktop } from "Hooks/useDesktop";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { useModelingIsin } from "Hooks/useModelingIsin";
import { useCreateContractTitle } from "Hooks/Contract/useCreateContractTitle";
import { useDistributionsAccessControlRedirect } from "Hooks/AccessControl/useDistributionsAccessControlRedirect";

export type PageStepProps = {
  isActive: boolean;
};

const AllSections: {
  [key in NewContractStep]: FunctionComponent<PageStepProps>;
} = {
  [NewContractStep.PathSelection]: PathSelection,
  [NewContractStep.PersonalData]: PersonalData,
  [NewContractStep.ContantVerification]: PersonalDataVerification,
  [NewContractStep.ContactInformation]: ContactInformation,
  [NewContractStep.Documents]: Documents,
  [NewContractStep.BankConnection]: BankConnection,
  [NewContractStep.AMLQuestions]: AMLQuestions,
  [NewContractStep.LastInformation]: LastInformation,
  [NewContractStep.ContractPreview]: ContractPreview,
  [NewContractStep.ContractSignature]: ContractSignature,
  [NewContractStep.FinalPage]: FinalPage,
  [NewContractStep.BankId]: BankIDPage,
};

export const CreatePage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isLoading: isLoadingAccessControl } =
    useDistributionsAccessControlRedirect(getPath(AppRouting.ContractDealer));
  const modelingCompleted = useAppSelector(
    s => s.contracts.modeling.modelingState.modelingCompleted,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const modelingFormData = useAppSelector(
    s => s.contracts.modeling.modelingState.formData,
  );
  const isAkatNoticeVisible = useAppSelector(
    s => s.contracts.create.isAkatNoticeVisible,
  );
  const confirmTerminateContract = useConfirmationDialog({
    confirmation: t(Resources.Contract.NewContract.TerminateModalTitle),
    cancelText: t(Resources.Common.Leave),
    confirmText: t(Resources.Common.Stay),
  });
  const { terminateContract } = useTerminateContract();
  const { creatingContract } = useCreateContract();
  const isin = useModelingIsin();

  const { data, error, isLoading } = useAppSelector(
    s => s.contracts.create.processInformation,
  );
  const { previousStep } = useCreateContractStep();
  const isDipSelected = useIsDipSelected();
  const { isDesktop } = useDesktop();
  const pageTitle = useCreateContractTitle();
  const isTitleShown = !isDesktop && !!pageTitle;

  const Components = useMemo(() => {
    if (!data?.steps) {
      return null;
    }

    return data?.steps.reduce<{
      [key in NewContractStep]?: FunctionComponent<{ isActive?: boolean }>;
    }>(
      (acc, curr) => ({
        ...acc,
        [curr]: AllSections[curr],
      }),
      {},
    );
  }, [data?.steps]);

  const actualStep = useAppSelector(e => e.contracts.create.actualStep);
  const initialStep = useRef(actualStep);
  const { isRehydrating, rehydrate, isRehydrated } =
    useRehydrateContractDraft();

  const onBackClick = async () => {
    if (actualStep === (data?.steps[0] ?? CONTRACT_STEPS[0])) {
      navigate(getPath(AppRouting.PreContractualInformation));
    } else if (
      actualStep === NewContractStep.PersonalData &&
      isAkatNoticeVisible
    ) {
      dispatch(setIsAkatNoticeVisible(false));
    } else if (actualStep === NewContractStep.ContractSignature) {
      // we need to wait for the contract to be created before we can terminate it
      if (creatingContract) {
        return;
      }

      const result = await confirmTerminateContract();

      if (result === ConfirmValues.Cancel) {
        terminateContract();
        dispatch(setBiometricsSignatureType(null));
        dispatch(resetBiometricSignatureError());

        previousStep();
      }
    } else {
      previousStep();
    }
  };

  useEffect(() => {
    rehydrate();
  }, [rehydrate]);

  useEffect(() => {
    // prefetch business sectors
    dispatch(getBusinessSectorAsync.request());

    // this should not occur, as we are not able to get to this page without modeling
    if (
      !modelingFormData[ContractModelingStep.Fund]?.currency ||
      !modelingFormData[ContractModelingStep.Fund]?.isin
    ) {
      return;
    }

    dispatch(
      getProcessInformationAsync.request({
        currency: modelingFormData[ContractModelingStep.Fund].currency,
        isin: modelingFormData[ContractModelingStep.Fund].isin,
        singleInvestment:
          modelingFormData[ContractModelingStep.FundConfiguration]
            ?.singleInvestment ?? 0,
        periodicalInvestment:
          modelingFormData[ContractModelingStep.FundConfiguration]
            ?.periodicalInvestment ?? 0,
        actualStep: initialStep.current,
      }),
    );
  }, [dispatch, modelingFormData]);

  //TODO fix empty actual step
  const actualNewContractStep =
    NewContractStep[actualStep] ?? NewContractStep.PathSelection;

  useViewTracking({
    category: "PageView",
    event: "Open",
    action: "NextStep",
    value: actualNewContractStep,
    url: `${getDefaultTrackingUrlBase()}#${actualNewContractStep}`,
  });

  useEffect(() => {
    setCustomDefaultUrlPostfix(`#${actualNewContractStep}`);

    return () => {
      setCustomDefaultUrlPostfix(null);
    };
  }, [actualNewContractStep]);

  if (!modelingCompleted && isRehydrated) {
    return <Navigate to={getPath(AppRouting.ContractModeling)} />;
  }

  const Component = Components?.[actualStep];

  return (
    <AuthenticatedLayout
      title={t(Resources.Contract.NewContract.Title)}
      isTitleShown={isTitleShown}
      onBackClick={
        actualStep !== NewContractStep.FinalPage ? onBackClick : undefined
      }
      backButtonDisabled={creatingContract}
      headerChildren={
        !isDesktop && isDipSelected ? <DipTagWrapper inverse /> : undefined
      }
      productIsin={isin ?? ""}
      titleComponent={pageTitle}
    >
      <LoadingWrapper
        isLoading={
          !Components || isLoading || isRehydrating || isLoadingAccessControl
        }
        error={error}
        skeleton={<LoadingSkeleton />}
      >
        {Component && <Component isActive />}
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};

const LoadingSkeleton = () => <PrimarySkeleton fullWidth height={500} />;

export default CreatePage;
