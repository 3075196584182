import { Link, Stack, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useTransferDipTitle } from "Hooks/Contract/TransferDip/useTransferDipTitle";
import { InvestmentDetailTabs } from "Hooks/ContractDetail/useMenuTabs";
import { useAppSelector } from "Hooks/useAppSelector";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { Link as RLink } from "react-router-dom";

const PageResources = Resources.Contract.DipTransfer.FinalPage;

export const TransferFinalPage: FunctionComponent = () => {
  const { t } = useResource();
  const { contractID } = useAppSelector(s => s.contracts.transferDip);
  const title = useTransferDipTitle();
  const navigate = useNavigate();

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{title}</PageTitle>
        <Stack spacing={2}>
          <Typography>{t(PageResources.Description)}</Typography>
          <Typography>{t(PageResources.Information.Description)}</Typography>
          <Typography>
            {t(PageResources.Information.References.Contract.Before)}
            <Link
              component={RLink}
              to={
                contractID
                  ? getPath(
                      AppRouting.ContractDetail,
                      contractID,
                      InvestmentDetailTabs.Contract,
                    )
                  : getPath(AppRouting.Dashboard)
              }
              fontWeight={600}
              color="secondary"
            >
              {t(PageResources.Information.References.Contract.Link)}
            </Link>
          </Typography>
          <Typography>
            {t(PageResources.Information.References.PaymentInstructions.Before)}
            <Link
              component={RLink}
              to={
                contractID
                  ? getPath(
                      AppRouting.ContractDetail,
                      contractID,
                      InvestmentDetailTabs.PaymentInformation,
                    )
                  : getPath(AppRouting.Dashboard)
              }
              fontWeight={600}
              color="secondary"
            >
              {t(PageResources.Information.References.PaymentInstructions.Link)}
            </Link>
            <Typography component="span">
              {t(
                PageResources.Information.References.PaymentInstructions.After,
              )}
            </Typography>
          </Typography>
        </Stack>
      </div>
      <PrimaryButton
        color="primary"
        onClick={() =>
          contractID
            ? navigate(getPath(AppRouting.ContractDetail, contractID))
            : navigate(getPath(AppRouting.Dashboard))
        }
      >
        {t(PageResources.ButtonText)}
      </PrimaryButton>
    </>
  );
};
