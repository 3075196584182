import { PurchaseFormDataWrapper } from "Components/ContractPurchase/Purchase/PurchaseFormDataWrapper";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { investUninvestedDepositsAsync } from "State/Contracts/UninvestedDeposits/Invest/InvestUninvestedDepositsState";
import { type FunctionComponent } from "react";
import { useInvestUninvestedDepositsTitle } from "Hooks/Contract/useInvestUninvestedDepositsTitle";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { setInvestUninvestedDepositsStep } from "State/Contracts/UninvestedDeposits/UninvestedDepositsReducer";
import { InvestUninvestedDepositsStep } from "State/Contracts/UninvestedDeposits/UninvestedDepositsReducer";
import { setInvestUninvestedDepositsLastRequest } from "State/Contracts/UninvestedDeposits/UninvestedDepositsReducer";

type Props = {
  contractID: number;
  onSelectedProductChange: (isin: string | null) => void;
};

export const ConfigurationStep: FunctionComponent<Props> = props => {
  const dispatch = useAppDispatch();
  const pageTitle = useInvestUninvestedDepositsTitle();
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();

  return (
    <PurchaseFormDataWrapper
      pageTitle={pageTitle}
      contractID={props.contractID}
      investmentType="Target"
      decimalPlaces={2}
      onSubmit={({ fundISIN, currency, singleInvestment, isDip }) => {
        if (isDip) {
          dispatch(
            setInvestUninvestedDepositsLastRequest({
              amount: singleInvestment!,
              contractID: props.contractID,
              currency: currency,
              isin: fundISIN,
              isBiometry: canUseBiometricsForSigning,
            }),
          );
          dispatch(
            setInvestUninvestedDepositsStep(
              InvestUninvestedDepositsStep.AkatNotice,
            ),
          );
          return;
        }

        dispatch(
          investUninvestedDepositsAsync.request({
            amount: singleInvestment!,
            contractID: props.contractID,
            currency: currency,
            isin: fundISIN,
            isBiometry: canUseBiometricsForSigning,
          }),
        );
      }}
      defaultIsin={null}
      onSelectedProductChange={props.onSelectedProductChange}
    />
  );
};
