import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { AkatNotice } from "Components/Shared/Akat/AkatNotice";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { ContractGuideWrapper } from "Components/Shared/ContractGuideWrapper";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { PageTitle } from "Components/Shared/PageTitle";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { useDesktop } from "Hooks/useDesktop";
import { useModelingColor } from "Hooks/useModelingColor";
import { useModelingIsin } from "Hooks/useModelingIsin";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";

const PageResources = Resources.Contract.NewContract.AkatNotice;

export const AkatNoticePage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const { color, contrastColor } = useModelingColor();

  const navigate = useNavigate();
  const isDipSelected = useIsDipSelected();
  const isin = useModelingIsin();

  const handleSubmit = () => {
    navigate(getPath(AppRouting.PreContractualInformation));
  };

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(getPath(AppRouting.ContractModeling))}
      headerChildren={
        isDipSelected && !isDesktop ? <DipTagWrapper inverse /> : undefined
      }
      productIsin={isin ?? ""}
    >
      <>
        <div>
          <ContractGuideWrapper
            isDipWrapped={isDipSelected}
            productIsin={isin ?? ""}
          >
            <PageTitle showOnMobile={false}>{t(PageResources.Title)}</PageTitle>
          </ContractGuideWrapper>
          <AkatNotice />
        </div>

        <PrimaryButton
          fullWidth={true}
          color="primary"
          onClick={handleSubmit}
          trackingTag={t(Resources.Common.Continue)}
          hexColor={contrastColor}
          hexBackgroundColor={color}
        >
          {t(Resources.Common.Continue)}
        </PrimaryButton>
      </>
    </AuthenticatedLayout>
  );
};
