import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { setTransferDipStep } from "State/Contracts/TransferDip/TransferDipReducer";
import { TransferDipStep } from "State/Contracts/TransferDip/TransferDipModels";
import { BiometricsSignatureType } from "State/Biometrics/BiometricsActions";
import { SignatureForm } from "Components/ContractBankConnections/Shared/SignatureForm";
import { PageTitle } from "Components/Shared/PageTitle";
import { useTransferDipTitle } from "Hooks/Contract/TransferDip/useTransferDipTitle";

const ComponentResources = Resources.Contract.DipTransfer.Signature;

export const TransferSignature: FunctionComponent = () => {
  const { t } = useResource();
  const { isLoading, phone, signatureHash, error } = useAppSelector(
    s => s.contracts.transferDip,
  );

  const title = useTransferDipTitle();
  const dispatch = useAppDispatch();

  return (
    <SignatureForm
      isLoading={isLoading}
      contractID={null}
      signatureHash={signatureHash}
      sms={{
        phone: phone ?? "",
      }}
      biometrics={{
        signatureType: BiometricsSignatureType.TransferDip,
      }}
      translations={{
        isLoading: t(ComponentResources.WorkingText),
        sms: {
          title: title,
          codeSent: t(Resources.Signature.SmsSent),
          codeDetermination: t(ComponentResources.CodeDetermination),
          verificationError: t(ComponentResources.VerificationError),
          button: t(ComponentResources.Submit),
        },
        biometrics: {
          title: t(ComponentResources.Biometrics),
          signatureError: t(ComponentResources.BiometricsError),
          signatureInProcessError: t(
            ComponentResources.BiometricsInProcessError,
          ),
        },
        signatureHashNotFoundError: t(
          ComponentResources.SignatureHashNotFoundError,
        ),
      }}
      components={{
        sms: {
          title: <PageTitle showOnMobile={false}>{title}</PageTitle>,
        },
        biometrics: {
          title: (
            <PageTitle showOnMobile={false}>
              {t(ComponentResources.Biometrics)}
            </PageTitle>
          ),
        },
      }}
      error={error}
      onSuccess={() => {
        dispatch(setTransferDipStep(TransferDipStep.FinalPage));
      }}
    />
  );
};
