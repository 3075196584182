import { BankIDProcessAudience, getBankIdAuthNativeCallback } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { put, takeLatest } from "typed-redux-saga";

export const handleBankIDCallbackNativeFlowAsync = createAsyncAction(
  "@bank-id/HANDLE_BANK_ID_CALLBACK_NATIVE_FLOW_REQUEST",
  "@bank-id/HANDLE_BANK_ID_CALLBACK_NATIVE_FLOW_SUCCESS",
  "@bank-id/HANDLE_BANK_ID_CALLBACK_NATIVE_FLOW_FAILURE",
)<
  {
    processID: string;
    processAudience: BankIDProcessAudience;
    accessToken?: string | null;
    error?: string | null;
    errorDescription?: string | null;
    redirectFromWebToNative: () => void;
  },
  void,
  void
>();

function* handleBankIDCallbackNativeFlow(
  action: ReturnType<typeof handleBankIDCallbackNativeFlowAsync.request>,
): Generator {
  const {
    processID,
    processAudience,
    accessToken,
    error: authError,
    errorDescription,
    redirectFromWebToNative,
  } = action.payload;

  try {
    const { error } = yield* safeApiCall(
      getBankIdAuthNativeCallback,
      processID,
      processAudience,
      accessToken,
      authError,
      errorDescription,
    );

    if (error) {
      yield put(handleBankIDCallbackNativeFlowAsync.failure());
      return;
    }

    yield put(handleBankIDCallbackNativeFlowAsync.success());
  } catch (err) {
    yield put(handleBankIDCallbackNativeFlowAsync.failure());
  } finally {
    yield redirectFromWebToNative();
  }
}

export function* watchHandleBankIDCallbackNativeFlowSaga() {
  yield takeLatest(
    getType(handleBankIDCallbackNativeFlowAsync.request),
    handleBankIDCallbackNativeFlow,
  );
}
