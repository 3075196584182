import { Box, Typography } from "@mui/material";
import { ContractPreviewDownloadLink } from "Components/ContractCreate/Shared/ContractPreviewDownloadLink";
import { BlSlideButton } from "Components/Shared/Buttons/BlSlideButton/BlSlideButton";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { transferDipInitializeSignatureAsync } from "State/Contracts/TransferDip/TransferDipInitializeSignatureState";
import { transferDipPreviewAsync } from "State/Contracts/TransferDip/TransferDipPreviewState";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent, useEffect } from "react";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { PageTitle } from "Components/Shared/PageTitle";
import { useTransferDipTitle } from "Hooks/Contract/TransferDip/useTransferDipTitle";

const PageResources = Resources.Contract.DipTransfer.Preview;

export const TransferPreview: FunctionComponent = _ => {
  const { t } = useResource();
  const title = useTransferDipTitle();
  const dispatch = useAppDispatch();
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();

  const {
    contractID,
    requestHash,
    signatureHash,
    contractPreview,
    isLoading: isLoadingPreview,
    error,
  } = useAppSelector(s => s.contracts.transferDip);

  useEffect(() => {
    if (contractID && requestHash) {
      dispatch(
        transferDipPreviewAsync.request({
          contractID: contractID,
          requestHash: requestHash,
        }),
      );
    }
  }, [dispatch, contractID, requestHash]);

  const isLoading = isLoadingPreview && !contractPreview;

  return (
    <>
      <Box display="flex" flexGrow={1} flexDirection="column">
        <PageTitle showOnMobile={false}>{title}</PageTitle>
        <Typography marginBottom={1}>{t(PageResources.Description)}</Typography>

        <ContractPreviewDownloadLink
          base64String={contractPreview}
          isLoading={isLoadingPreview}
          fileName={t(PageResources.FileName)}
        />
      </Box>
      <BlSlideButton
        minSlideVelocity={0.95}
        minSlideWidth={0.95}
        onSlideDone={() => {
          dispatch(
            transferDipInitializeSignatureAsync.request({
              signatureHash,
              isBiometry: canUseBiometricsForSigning,
            }),
          );
        }}
        isLoading={isLoading}
        disabled={isLoading || !!error}
        trackingTag={t(PageResources.SignatureTrackingTag)}
      >
        {t(PageResources.ConfirmText)}
      </BlSlideButton>
    </>
  );
};
