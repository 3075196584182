import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent, useEffect } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { useNavigate, useParams } from "react-router";
import { TransferDipStep } from "State/Contracts/TransferDip/TransferDipModels";
import {
  resetTransferDipState,
  setTransferDipContractID,
  setTransferDipStep,
} from "State/Contracts/TransferDip/TransferDipReducer";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { PageTitle } from "Components/Shared/PageTitle";
import { Box, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useSettings } from "Hooks/App/Settings/useSettings";
import { clearAppSettings } from "State/App/Settings/GetSettingsState";
import { useIsSelfNegotiatedContract } from "Hooks/Contract/Detail/useIsSelfNegotiatedContract";
import { useDesktop } from "Hooks/useDesktop";
import { useTransferDipTitle } from "Hooks/Contract/TransferDip/useTransferDipTitle";
import { getClientAsync } from "State/Client/Client/GetClient";
import { TransferInfo } from "Components/ContractTransferDip/TransferInfo/TransferInfo";
import { AkatNoticeStep } from "Components/ContractTransferDip/AkatNoticeStep";
import { TransferPreview } from "Components/ContractTransferDip/TransferPreview/TransferPreview";
import { TransferSignature } from "Components/ContractTransferDip/TransferSignature/TransferSignature";
import { TransferFinalPage } from "Components/ContractTransferDip/FinalPage/TransferFinalPage";

const PageResources = Resources.Contract.DipTransfer;

export const TransferDip: FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const contractID = Number.parseInt(params.id as string);
  const title = useTransferDipTitle();
  const { actualStep, error } = useAppSelector(s => s.contracts.transferDip);
  const { settings, isLoading: isLoadingSettings } = useSettings();
  const { isDesktop } = useDesktop();

  const {
    isLoading: isLoadingClient,
    data: client,
    error: clientError,
  } = useAppSelector(s => s.client.client);

  const {
    isLoading: isLoadingOverview,
    data: overview,
    error: overviewError,
  } = useAppSelector(state => state.investmentsDetail.overview);

  const isSelfNegotiatedContract = useIsSelfNegotiatedContract(overview?.type);

  useEffect(() => {
    dispatch(resetTransferDipState());
    dispatch(clearAppSettings());
    dispatch(setTransferDipContractID(contractID));
    dispatch(getOverviewAsync.request(contractID));
    dispatch(getClientAsync.request());
  }, [dispatch, contractID]);

  const isLoading =
    (isLoadingOverview && !overview) ||
    (isLoadingClient && !client) ||
    (isLoadingSettings && !settings);

  return (
    <AuthenticatedLayout
      title={title}
      isTitleShown={!isDesktop}
      onBackClick={() => {
        switch (actualStep) {
          case TransferDipStep.AkatNotice:
            dispatch(setTransferDipStep(TransferDipStep.TransferInformation));
            break;
          case TransferDipStep.TransferSignature:
            dispatch(setTransferDipStep(TransferDipStep.TransferPreview));
            break;
          default:
            navigate(getPath(AppRouting.ContractDetail, contractID));
            break;
        }
      }}
    >
      <LoadingWrapper
        isLoading={isLoading}
        skeleton={<LoadingSkeleton />}
        error={error || overviewError || clientError}
      >
        {!overview?.isDip &&
          settings?.isDIPEnabled &&
          isSelfNegotiatedContract && (
            <>
              {actualStep === TransferDipStep.TransferInformation && (
                <TransferInfo />
              )}
              {actualStep === TransferDipStep.AkatNotice && <AkatNoticeStep />}
              {actualStep === TransferDipStep.TransferPreview && (
                <TransferPreview />
              )}
              {actualStep === TransferDipStep.TransferSignature && (
                <TransferSignature />
              )}
              {actualStep === TransferDipStep.FinalPage && (
                <TransferFinalPage />
              )}
            </>
          )}
        {(overview?.isDip ||
          !settings?.isDIPEnabled ||
          !isSelfNegotiatedContract) && (
          <>
            <div>
              <PageTitle>{t(PageResources.Title)}</PageTitle>
              <Typography>
                {overview?.isDip
                  ? t(PageResources.AlreadyTransferredMessage)
                  : t(PageResources.UnavailableMessage)}
              </Typography>
            </div>
            <PrimaryButton
              color="primary"
              onClick={() =>
                contractID
                  ? navigate(getPath(AppRouting.ContractDetail, contractID))
                  : navigate(getPath(AppRouting.Dashboard))
              }
            >
              {t(PageResources.FinalPage.ButtonText)}
            </PrimaryButton>
          </>
        )}
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};

const LoadingSkeleton = () => (
  <>
    <div>
      <PrimarySkeleton
        height={30}
        fullHeight={false}
        width="50%"
        marginBottom={10}
      />
      <PrimarySkeleton height={60} fullHeight={false} marginBottom={30} />
      <PrimarySkeleton
        height={20}
        fullHeight={false}
        width="50%"
        marginBottom={20}
      />
      <PrimarySkeleton
        height={60}
        fullHeight={false}
        width="75%"
        marginBottom={30}
      />
      <PrimarySkeleton
        height={20}
        fullHeight={false}
        width="50%"
        marginBottom={20}
      />
      <PrimarySkeleton
        height={130}
        fullHeight={false}
        width="75%"
        marginBottom={20}
      />
      <Box display="flex" justifyContent="end">
        <PrimarySkeleton
          height={20}
          fullHeight={false}
          width={140}
          marginBottom={20}
        />
      </Box>
      <PrimarySkeleton
        height={20}
        fullHeight={false}
        width="50%"
        marginBottom={10}
      />
      <PrimarySkeleton height={60} fullHeight={false} marginBottom={20} />
      <PrimarySkeleton height={90} fullHeight={false} />
    </div>
    <PrimarySkeleton height={60} fullHeight={false} />
  </>
);
