import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { Step } from "Components/ContractCreate/Shared/Step";
import { HiddenSubmitButton } from "Components/Shared/Buttons/HiddenSubmitButton";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { PageTitle } from "../Shared/PageTitle";
import {
  UNDER_AGE_TYPE,
  ValidatedCountries,
  useYupFormSchema,
} from "Hooks/Contract/PersonalData/useYupFormSchema";
import { useFormData } from "Hooks/Contract/useFormData";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { Resources, useResource } from "Translations/Resources";
import { useEffect, type FunctionComponent, useMemo, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { CountriesDropdown } from "Components/ContractCreate/PersonalData/components/CountriesDropdown";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { PersonalIdentificatonNumberPart } from "Components/ContractCreate/PersonalData/components/PersonalIdentificatonNumberPart";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { useAppSelector } from "Hooks/useAppSelector";
import { PersonalIdentificatonNumberVerification } from "Components/ContractCreate/PersonalData/components/PersonalIdentificatonNumberVerification";
import { PhoneInput } from "Components/Shared/Inputs/SpecificInputs/PhoneInput";
import {
  NewContractStep,
  PersonalDataFormModel,
  VerificationMode,
} from "State/Contracts/Create/Models";
import { USA_NATIONALITY_FORBID_TYPE } from "Constants/Contracts/Create";
import { useDuplicityValidation } from "Hooks/Contract/PersonalData/useDuplicityValidation";
import { useConfirmationDialog } from "Hooks/Dialogs/useConfirmationDialog";
import { ConfirmValues } from "Components/Shared/Dialogs/ConfirmDialog";
import { MODELLING_PERSIST_SESSION_STORAGE_KEY } from "Constants/Contracts/Modeling";
import { resetUser } from "State/Auth/AuthReducer";
import { useNavigate } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { ValidationError } from "yup";
import { clearBankIDProfileError } from "State/Contracts/Create/BankIDProfile/SetBankIDProfile";
import { PageStepProps } from "Pages/Contracts/CreatePage";
import {
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
} from "Constants/Countries";
import { useModelingColor } from "Hooks/useModelingColor";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import {
  AkatNotice,
  validateInvestmentHorizonReachedAfterRetirementAge,
} from "Components/Shared/Akat/AkatNotice";
import { setIsAkatNoticeVisible } from "State/Contracts/Create/CreateState";

const PageResources = Resources.Contract.NewContract.PersonalInformation;
const FormResources = Resources.Forms.PersonalData;

const StyledForm = styled.form`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

type Props = PageStepProps;

export const PersonalData: FunctionComponent<Props> = ({ isActive }) => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const schema = useYupFormSchema();
  const countries = useCountriesList();
  const isBankIDVerified = useIsBankIDVerified();
  const isDipSelected = useIsDipSelected();
  const confirm = useConfirmationDialog({
    confirmation: `${t(PageResources.DuplicityMessage)} ${t(
      PageResources.DuplicityConfirmation,
    )}`,
  });
  const confirmWaiting = useRef(false);
  const navigate = useNavigate();
  const { color, contrastColor } = useModelingColor();
  const { nextStep } = useCreateContractStep();

  const isPhoneVerified = useAppSelector(
    s => s.contracts.create.isPhoneVerified,
  );
  const isIdentityNumberVerified = useAppSelector(
    s => s.contracts.create.isIdentityNumberVerified,
  );
  const bankIdVerificationError = useAppSelector(
    s => s.contracts.create.bankIdVerificationError,
  );
  const modelling = useAppSelector(
    s => s.contracts.modeling.modelingState.formData,
  );
  const { data: processInformation } = useAppSelector(
    s => s.contracts.create.processInformation,
  );
  const isAkatNoticeVisible = useAppSelector(
    s => s.contracts.create.isAkatNoticeVisible,
  );

  const { defaultValues } = useFormData<NewContractStep.PersonalData>({
    step: NewContractStep.PersonalData,
  });
  const { defaultValues: pathSelectionValues } =
    useFormData<NewContractStep.PathSelection>({
      step: NewContractStep.PathSelection,
    });
  const form = useForm<PersonalDataFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      nationalities: [],
      ...defaultValues,
    },
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    trigger,
    setValue,
  } = form;

  const birthdate = watch("birthDate");

  const isInvestmentHorizonReachedAfterRetirementAge =
    validateInvestmentHorizonReachedAfterRetirementAge(birthdate) === true;

  const isAkatNoticeViewable =
    isDipSelected && isInvestmentHorizonReachedAfterRetirementAge;

  const onSubmit = (formData: PersonalDataFormModel) => {
    if (isAkatNoticeViewable && !isAkatNoticeVisible) {
      dispatch(setIsAkatNoticeVisible(true));
      return;
    }

    nextStep({
      formData,
    });
  };

  const nationalities = watch("nationalities");
  const birthCountry = watch("birthCountry");
  const personalIdentificationNumber = watch("personalIdentificationNumber");

  const { disabledSubmit, isLoading, validate, existence } =
    useDuplicityValidation(
      personalIdentificationNumber,
      nationalities?.[0]?.value,
    );

  const isOnlyPersonalNumberVerificationShown =
    !isIdentityNumberVerified && isBankIDVerified;

  useEffect(() => {
    const confirmation = async () => {
      if (confirmWaiting.current) {
        return;
      }

      confirmWaiting.current = true;
      const result = await confirm();

      if (result === ConfirmValues.Proceed) {
        sessionStorage.setItem(
          MODELLING_PERSIST_SESSION_STORAGE_KEY,
          JSON.stringify(modelling),
        );
        dispatch(
          resetUser({
            wasSignedOutManually: false,
          }),
        );
        navigate(getPath(AppRouting.SignIn));
      }

      confirmWaiting.current = false;
    };

    if (existence && !isOnlyPersonalNumberVerificationShown) {
      confirmation();
    }
  }, [
    confirm,
    existence,
    modelling,
    dispatch,
    navigate,
    isOnlyPersonalNumberVerificationShown,
  ]);

  const validatedNationality = useMemo(() => {
    return !!nationalities?.some(({ value }) =>
      ValidatedCountries.includes(value),
    );
  }, [nationalities]);

  const personalIdentificationMask = useMemo(() => {
    if (
      nationalities?.some(({ value }) =>
        [CZECHIA_COUNTRY_CODE, SLOVAKIA_COUNTRY_CODE].includes(value),
      )
    ) {
      return "000000/0000";
    }

    return /^.+$/;
  }, [nationalities]);

  const onErrorContinue = () => {
    dispatch(clearBankIDProfileError());
  };

  useEffect(() => {
    if (
      nationalities?.length &&
      // verificatio mode is only for bankId process
      (isIdentityNumberVerified ||
        pathSelectionValues?.verificationMode !== VerificationMode.BankID) &&
      !bankIdVerificationError &&
      isActive
    ) {
      trigger("nationalities");
      validate();
    }
  }, [
    nationalities,
    trigger,
    validate,
    isIdentityNumberVerified,
    bankIdVerificationError,
    isActive,
    pathSelectionValues?.verificationMode,
  ]);

  useEffect(() => {
    if (birthCountry) {
      trigger("birthCountry");
    }
  }, [birthCountry, trigger]);

  useEffect(() => {
    if (processInformation?.email) {
      setValue("email", processInformation?.email);
    }
  }, [setValue, processInformation?.email]);

  useEffect(() => {
    if (processInformation?.phone) {
      setValue("phone", processInformation?.phone);
    }
  }, [setValue, processInformation?.phone]);

  if (bankIdVerificationError) {
    if (bankIdVerificationError instanceof ValidationError) {
      console.info(
        `BankID property error: ${bankIdVerificationError.path}, message: ${bankIdVerificationError.message}`,
      );
    }

    return (
      <>
        <Typography color="error">
          {bankIdVerificationError instanceof ValidationError
            ? t(PageResources.BankIDMissingValuesError)
            : t(PageResources.BankIDError)}
        </Typography>
        <PrimaryButton
          color="primary"
          onClick={onErrorContinue}
          hexColor={contrastColor}
          hexBackgroundColor={color}
        >
          {t(Resources.Common.Continue)}
        </PrimaryButton>
      </>
    );
  }

  if (isOnlyPersonalNumberVerificationShown) {
    return (
      <PersonalIdentificatonNumberVerification validateExistence={validate} />
    );
  }

  return (
    <>
      <div>
        <Step actualStep={1} />

        {isAkatNoticeVisible && (
          <>
            <PageTitle>{t(PageResources.AkatNotice.Title)}</PageTitle>
            <AkatNotice
              isExceedingMaximumTaxBenefitLimitationsNoticeVisible={false}
              birthdate={birthdate}
            />
          </>
        )}

        {!isAkatNoticeVisible && (
          <>
            <PageTitle>{t(PageResources.Title)}</PageTitle>
            <Typography>{t(PageResources.Label)}</Typography>
          </>
        )}

        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          hidden={isAkatNoticeVisible}
        >
          <FormProvider {...form}>
            <CountriesDropdown />

            <PersonalIdentificatonNumberPart
              validatedNationality={validatedNationality}
              validateExistence={validate}
              isExistence={existence}
              personalIdentificationMask={personalIdentificationMask}
            />

            <BlInputFormField
              control={control}
              name="firstName"
              errors={errors}
              label={t(PageResources.FirstName)}
              disabled={isBankIDVerified}
            />
            <BlInputFormField
              control={control}
              name="lastName"
              errors={errors}
              label={t(PageResources.LastName)}
              disabled={isBankIDVerified}
            />

            <BlInputFormField
              control={control}
              name="titleBeforeName"
              errors={errors}
              label={t(FormResources.TitleBeforeName)}
            />
            <BlInputFormField
              control={control}
              name="titleAfterName"
              errors={errors}
              label={t(FormResources.TitleAfterName)}
            />

            <BlInputFormField
              control={control}
              name="birthCity"
              errors={errors}
              label={t(FormResources.BirthCity)}
              disabled={isBankIDVerified}
            />

            <DropdownInput
              control={control}
              errors={errors}
              name="birthCountry"
              options={countries}
              label={t(FormResources.BirthCountry)}
              disabled={isBankIDVerified}
            />

            <InputHeaderWrapper
              header={t(PageResources.Constant)}
              marginTop={4}
            >
              <PhoneInput
                control={control}
                name="phone"
                errors={errors}
                disabled={
                  !processInformation?.isPhoneEditable || isPhoneVerified
                }
                readonly={
                  !processInformation?.isPhoneEditable || isPhoneVerified
                }
              />
              <BlInputFormField
                control={control}
                name="email"
                disabled
                errors={errors}
                label={t(Resources.Common.Email)}
              />
            </InputHeaderWrapper>

            <HiddenSubmitButton />
          </FormProvider>
        </StyledForm>
      </div>
      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoading}
        disabled={
          errors.birthDate?.type === UNDER_AGE_TYPE ||
          errors.nationalities?.type === USA_NATIONALITY_FORBID_TYPE ||
          errors?.birthCountry?.type === USA_NATIONALITY_FORBID_TYPE ||
          disabledSubmit
        }
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
