import { useAppSelector } from "Hooks/useAppSelector";
import { useMemo } from "react";
import { TransferDipStep } from "State/Contracts/TransferDip/TransferDipModels";
import { Resources, useResource } from "Translations/Resources";

const PageResources = Resources.Contract.DipTransfer;

export const useTransferDipTitle = () => {
  const { t } = useResource();
  const { actualStep } = useAppSelector(s => s.contracts.transferDip);

  return useMemo(() => {
    switch (actualStep) {
      case TransferDipStep.TransferInformation:
        return t(PageResources.Information.Title);
      case TransferDipStep.AkatNotice:
        return t(PageResources.AkatNotice.Title);
      case TransferDipStep.TransferPreview:
        return t(PageResources.Preview.Title);
      case TransferDipStep.TransferSignature:
        return t(PageResources.Signature.Title);
      default:
        return t(PageResources.Title);
    }
  }, [actualStep, t]);
};
