import { AkatNotice } from "Components/Shared/Akat/AkatNotice";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useInvestUninvestedDepositsTitle } from "Hooks/Contract/useInvestUninvestedDepositsTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent } from "react";
import { Resources, useResource } from "Translations/Resources";
import { getProductColor } from "Utils/Products";
import { investUninvestedDepositsAsync } from "State/Contracts/UninvestedDeposits/Invest/InvestUninvestedDepositsState";

export const AkatNoticeStep: FunctionComponent = () => {
  const { t } = useResource();
  const { lastRequest } = useAppSelector(s => s.contracts.uninvestedDeposits);
  const { color, contrastColor } = getProductColor(lastRequest?.isin);

  const title = useInvestUninvestedDepositsTitle();
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    if (lastRequest) {
      dispatch(investUninvestedDepositsAsync.request(lastRequest));
    }
  };

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{title}</PageTitle>
        <AkatNotice
          isInvestmentHorizonReachedAfterRetirementAgeNoticeVisible={false}
        />
      </div>

      <PrimaryButton
        fullWidth
        color="primary"
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
        onClick={handleSubmit}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
