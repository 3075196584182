import { AkatNotice } from "Components/Shared/Akat/AkatNotice";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useTransferDipTitle } from "Hooks/Contract/TransferDip/useTransferDipTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent } from "react";
import { TransferDipStep } from "State/Contracts/TransferDip/TransferDipModels";
import { setTransferDipStep } from "State/Contracts/TransferDip/TransferDipReducer";
import { transferDipAsync } from "State/Contracts/TransferDip/TransferDipState";
import { Resources, useResource } from "Translations/Resources";

export const AkatNoticeStep: FunctionComponent = () => {
  const { t } = useResource();
  const { contractID } = useAppSelector(s => s.contracts.transferDip);
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();
  const { data: client } = useAppSelector(s => s.client.client);

  const title = useTransferDipTitle();
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    if (!contractID) {
      return;
    }

    dispatch(setTransferDipStep(TransferDipStep.TransferPreview));
    dispatch(
      transferDipAsync.request({
        contractID,
        isBiometry: canUseBiometricsForSigning,
      }),
    );
  };

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{title}</PageTitle>
        <AkatNotice birthdate={client?.birthDate} />
      </div>

      <PrimaryButton
        fullWidth
        color="primary"
        trackingTag={t(Resources.Common.Continue)}
        onClick={handleSubmit}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
