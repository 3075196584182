import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";
import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { ObjectSchema, boolean, object } from "yup";
import { useState } from "react";
import { DipDescriptionDialog } from "Components/ContractModeling/FundSelector/Components/DipDescriptionDialog";
import {
  TransferDipInfoFormModel,
  TransferDipStep,
} from "State/Contracts/TransferDip/TransferDipModels";
import { useAppSelector } from "Hooks/useAppSelector";
import { setTransferDipStep } from "State/Contracts/TransferDip/TransferDipReducer";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { setTransferDipIsConsentGranted } from "../../../State/Contracts/TransferDip/TransferDipReducer";
import { PageTitle } from "Components/Shared/PageTitle";
import { useTransferDipTitle } from "Hooks/Contract/TransferDip/useTransferDipTitle";

const PageResources = Resources.Contract.DipTransfer.Information;

const Properties: (keyof typeof PageResources.KeyProperties.Properties)[] = [
  "TaxBenefit",
  "EmployerContribution",
  "TimeSpanRequirement",
  "WithdrawalAgeRequirement",
  "HigherYieldPotential",
  "SavingsAndWithdrawalFlexibility",
];

const useYupFormSchema = (): ObjectSchema<TransferDipInfoFormModel> => {
  const { t } = useResource();

  return object<TransferDipInfoFormModel>().shape({
    transferAccept: boolean()
      .required(t(PageResources.DecisionRequired))
      .isTrue(t(PageResources.DecisionRequired)),
  });
};

export const TransferInfo = () => {
  const { t } = useResource();
  const { isConsentGranted } = useAppSelector(s => s.contracts.transferDip);

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const title = useTransferDipTitle();
  const dispatch = useAppDispatch();
  const schema = useYupFormSchema();

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<TransferDipInfoFormModel>({
    resolver: yupResolver(schema),
    defaultValues: { transferAccept: isConsentGranted },
  });

  const onSubmit = () => {
    dispatch(setTransferDipIsConsentGranted(true));
    dispatch(setTransferDipStep(TransferDipStep.AkatNotice));
  };

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{title}</PageTitle>
        <Typography marginBottom={7}>{t(PageResources.Description)}</Typography>

        <Typography fontWeight={700} marginBottom={1}>
          {t(PageResources.MoreInformation.Title)}
        </Typography>
        <Typography marginBottom={2}>
          {t(PageResources.MoreInformation.Description.FirstPart)}
        </Typography>
        <Typography marginBottom={5}>
          {t(PageResources.MoreInformation.Description.SecondPart.FirstPart)}
          <Link component="span">
            {t(PageResources.MoreInformation.Description.SecondPart.SecondPart)}
          </Link>
          {t(PageResources.MoreInformation.Description.SecondPart.ThirdPart)}
        </Typography>

        <Typography fontWeight={700}>
          {t(PageResources.KeyProperties.Title)}
        </Typography>
        <ul>
          {Properties.map(key => (
            <li key={key}>{t(PageResources.KeyProperties.Properties[key])}</li>
          ))}
        </ul>

        <Link onClick={() => setIsDialogVisible(true)}>
          <Typography textAlign="right" marginBottom={4}>
            {t(PageResources.KeyProperties.MoreInfo)}
          </Typography>
        </Link>

        <Typography variant="h4" marginBottom={1}>
          {t(PageResources.Transfer.Title)}
        </Typography>

        <Typography marginBottom={3}>
          {t(PageResources.Transfer.Description)}
        </Typography>

        <BlCheckboxFormField
          control={control}
          name="transferAccept"
          label={t(PageResources.Transfer.ConfirmationText)}
          errors={errors}
        />

        <DipDescriptionDialog
          onClose={() => setIsDialogVisible(false)}
          isOpen={isDialogVisible}
        />
      </div>
      <PrimaryButton
        fullWidth
        color="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={!isValid}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
