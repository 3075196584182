import { Box, Typography } from "@mui/material";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useBankIDCallbackParams } from "Hooks/BankID/useBankIDCallbackParams";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { FunctionComponent, useEffect } from "react";
import { handleBankIDCallbackNativeFlowAsync } from "State/BankID/HandleBankIDCallbackNativeFlowState";
import { Resources, useResource } from "Translations/Resources";

const MOBILE_APP_REDIRECT_URL = "investika://callback";

const PageResources = Resources.BankID.NativeCallback;

export const BankIDNativeCallbackPage: FunctionComponent = () => {
  const { t } = useResource();
  const { accessToken, decodedState, error } = useBankIDCallbackParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!decodedState?.processID && !!decodedState?.processAudience) {
      dispatch(
        handleBankIDCallbackNativeFlowAsync.request({
          processID: decodedState.processID,
          processAudience: decodedState.processAudience,
          accessToken,
          error,
          redirectFromWebToNative,
        }),
      );
    }
  }, [
    decodedState?.processID,
    decodedState?.processAudience,
    accessToken,
    error,
    dispatch,
  ]);

  return (
    <UnauthenticatedLayout title={t(PageResources.Title)}>
      <Box marginBottom="auto">
        <PageTitle>{t(PageResources.Title)}</PageTitle>
        <Typography>{t(PageResources.Description)}</Typography>
      </Box>
      <PrimaryButton fullWidth onClick={redirectFromWebToNative}>
        {t(PageResources.RedirectFromWebToMobile)}
      </PrimaryButton>
    </UnauthenticatedLayout>
  );
};

function redirectFromWebToNative() {
  window.location.href = MOBILE_APP_REDIRECT_URL;
}
