import { AkatNotice } from "Components/Shared/Akat/AkatNotice";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { usePurchaseTitle } from "Hooks/Contract/usePurchaseTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent } from "react";
import { PurchaseStep } from "State/Contracts/Purchase/PurchaseState";
import { setPurchaseStep } from "State/Contracts/Purchase/PurchaseState";
import { Resources, useResource } from "Translations/Resources";
import { getProductColor } from "Utils/Products";

export const AkatNoticeStep: FunctionComponent = () => {
  const { t } = useResource();
  const { configuration } = useAppSelector(s => s.contracts.purchase);
  const { color, contrastColor } = getProductColor(configuration?.isin);

  const title = usePurchaseTitle();
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    dispatch(setPurchaseStep(PurchaseStep.Success));
  };

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{title}</PageTitle>
        <AkatNotice
          isInvestmentHorizonReachedAfterRetirementAgeNoticeVisible={false}
        />
      </div>

      <PrimaryButton
        fullWidth
        color="primary"
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
        onClick={handleSubmit}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
