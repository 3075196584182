import { FunctionComponent } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";

const StyledList = styled.ul`
  display: grid;
  gap: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(5)};
`;

const ComponentResources = Resources.Components.AkatNotice;
const InvestmentHorizonAfterRetirementAgeThresholdAge = 55;

type Props = {
  isExceedingMaximumTaxBenefitLimitationsNoticeVisible?: boolean;
  isInvestmentHorizonReachedAfterRetirementAgeNoticeVisible?: boolean;
  birthdate?: string | Date | null | undefined;
};

export const AkatNotice: FunctionComponent<Props> = ({
  isExceedingMaximumTaxBenefitLimitationsNoticeVisible = true,
  isInvestmentHorizonReachedAfterRetirementAgeNoticeVisible = true,
  birthdate,
}) => {
  const { t } = useResource();

  const isInvestmentHorizonReachedAfterRetirementAge =
    validateInvestmentHorizonReachedAfterRetirementAge(birthdate) === true;

  return (
    <StyledList>
      {isInvestmentHorizonReachedAfterRetirementAgeNoticeVisible &&
        isInvestmentHorizonReachedAfterRetirementAge && (
          <li>
            {t(ComponentResources.InvestmentHorizonReachedAfterRetirementAge)}
          </li>
        )}
      {isExceedingMaximumTaxBenefitLimitationsNoticeVisible && (
        <li>{t(ComponentResources.ExceedingMaximumTaxBenefitLimitations)}</li>
      )}
    </StyledList>
  );
};

export function validateInvestmentHorizonReachedAfterRetirementAge(
  birthdate: string | Date | null | undefined,
): boolean | null {
  if (!birthdate) {
    return null;
  }

  const today = new Date();

  today.setFullYear(
    today.getFullYear() - InvestmentHorizonAfterRetirementAgeThresholdAge,
  );

  if (typeof birthdate === "string") {
    return !isNaN(Date.parse(birthdate)) && new Date(birthdate) <= today;
  }

  return birthdate <= today;
}
